import React from 'react';
import CaptionImage from '../../components/elements/CaptionImage';

export const Malta = {
    "name": "Malta",
    "link": "malta",
    "country": "Europe",
    "continent": "Europe",
    "thumb": "https://live.staticflickr.com/65535/50380331346_4e2233ee9f_t.jpg",
    "image": "https://live.staticflickr.com/65535/50380331346_bbe5cd9171_o.jpg",
    "caption": "The Pearl Of The Mediterranean",

    "brief": "Malta is a small nation in the middle of the Mediterranean Sea, it is the 10th smallest country in the world, and is an archipelago formed by three main islands: Malta, Comino and Gozo. Despite its relative small size, Malta is a very dense country with remnants of occupation dating as far back as to 5900 BC. As such, this tiny country packs numerous historic sights and natural wonders sure to make any visit worth it.",

    "description": <p>
            Malta is a small nation in the middle of the Mediterranean Sea, it is the 10th smallest country in the world, and is an archipelago formed by three main islands: <b>Malta</b>, <b>Comino</b> and <b>Gozo</b>.
            Despite its relative small size, Malta is a very dense country with remnants of occupation dating as far back as to 5900 BC. As such, this tiny country packs numerous historic sights and natural wonders sure to make any visit worth it.
            <br/><br/>
            Its capital, Valletta, is the smallest capital in the world. Established in the XVI century by the Knights Of St. John, it was during part of history capital to the <i>Sovereign Military Order of Malta</i>.
            This resulted on this walled city being filled with important monuments, palaces and churches, the most famous of all being the <b>St. John's Co-Cathedral</b>.
            <br/><br/>
            Besides the capital, Malta is renowned for its many fortresses, megalithic and pre-historic temples and top class beaches.
            From the many man-made constructions dating back thousand of years, the most significant is the <b>Ħal Saflieni Hypogeum</b>, a series of subterranean halls and burial chambers dating to circa 3200 BC.
            <br/><br/>
            Despite still being an off the radar destination, Malta is seeing an increase in tourism each year.
            Perhaps, the most famous place on this country for tourists is the <b>Blue Lagoon</b>, situated on the island of Comino, that everyday draws crowds of tourists who want to swim on its shading blue waters and marvel at the natural scenery.
        </p>,

    "tips": <p>
            Malta is part of the European Union. As such you will need a visa for the Schengen Area in order to visit. You can learn more about it <a href="/travel-tips/visas#schengen" target="_blank" rel="noopener noreferrer">here</a>.
            <br/><br/>
            The currency used is the Euro and the native language is Maltese, although almost all of its citizens can speak English and/or Italian. They drive on the left side of the road.
        </p>,

    "nrDays": <p>
            Malta is a tiny but dense country, with a lot of important places to see. You will need at the bare minimum <b>4 days</b> to visit the main important sites on the island.
            We recommend you throw in one more day or two, and enjoy spending some time on the beach, relaxing and doing water sports.
        </p>,

    "whenVisit": <p>
            Due to its geographical position, Malta enjoys good weather all year long. Still, this is mainly a summer destination, and you will want the weather to be favorable for a swim on the Mediterranean sea.
            <br/><br/>
            On that side, August is the warmest and busiest month, followed by July, and while the number of tourists aren't yet too massive, we would recommend you <b>avoid</b> the month of August.
            As such, the beginning of <b>July</b> is the best time to visit the island, followed closely by the months of <b>June</b> and <b>September</b>, although sometimes you may catch rain or bad weather during those months.
            <br/><br/>
            If you don't fancy beaches too much, and want to visit only for the cultural sites, then visiting during Spring (between <b>April</b> and <b>May</b>) is the best choice.
        </p>,

    "mobility": <p>
            During your stay, you will be visiting places spread all over the island.
            Malta has a great network of public buses that can drop you at any major place on the island.
            Besides that you also have the main ride-sharing app, <a href="https://www.ecabs.com.mt/" target="_blank" rel="noopener noreferrer">eCabs</a>
            <br/><br/>
            If you are a confident driver, then without a doubt that <b>renting a car</b> is the best way to travel in Malta.
            Remember that in Malta cars drive on the left side of the road.
            This is a small country and you won't spend much gas during your stay. Beware though, transit in Malta is quite chaotic, and driving can be stressful.
            Cars are always trying to overcome each other and more often than not don't respect driving lanes. Be careful while driving and you should be fine.
            <br/><br/>
            If you are looking for options to rent, we recommend local companies such as <a href="https://princesscarhire.com/" target="_blank" rel="noopener noreferrer">Princess Car Hire</a>.
        </p>,

    "safety": <p>
            Malta is consistently ranked as one of the safest countries in the world, with crime rates very low, even pickpocketing is rare here.
            But as in any other place where you go during your travels, always pay close attention to your belongings, and don't leave them unattended on the beach.
            <br/><br/>
            There are some reports during the high season (July and August) of home robberies, mainly happening to tourists during their visit.
            The burglars operate during the day, when the tourists are visiting the country.
            As such, if you can, make sure you lock all the doors and windows before heading out.
        </p>,


    "itinerary": {
        "description": <p>
            As previously mentioned, 4 days are the bare minimum for a stay in Malta.
            If you have the time, you should stay one or two more days here and have a more relaxed time. Maybe spend one full day in the <b>Island of Gozo</b>, or book a visit to the UNESCO World Heritage Site of <b>Ħal Saflieni Hypogeum</b>.
            <br/><br/>
            Here we present you a full-packed 4 days itinerary on this country, that will have you visiting the most important and accessible sites on the archipelago.
        </p>,

        "days": [
            {
                "title": "Valletta and the 3 Cities",
                "description": <div>
                    Start your morning with a visit to Malta's capital, <b>Valletta</b>. This is the tinniest capital in the world, and so is easily walkable.
                    Founded in the 1500s, this city is filled with historic and significant buildings for you to visit.
                    <br/><br/>
                    The most important landmark in the city is the <b>St. John's Co-Cathedral</b>. This is a massive cathedral built by the <i>Order Of St. John</i> and dedicated to <i>Saint John the Baptist</i>.
                    Inside you will find nine eloquently decorated chapels, with eight of them constructed in honor of the languages of the <i>Knights of Saint John</i>, and the ninth dedicated to their patron saint, <i>Our Lady of Philermos</i>.
                    This cathedral also houses one of the most famous works by Caravaggio, known as <b>The Beheading Of St. John The Baptist</b> (1608), considered by many as one of the ten greatest works of art of all time.
                    Entrance to the church costs 15€ for adults and 7.5€ for students, and is absolutely a must when you are in Malta.
                    <br/><br/>
                    Other important buildings in the capital include the <b>Grand Master's Palace</b>, which houses the presidential office of Malta, and was once home to the Grand Masters of the Order Of Malta.
                    There is also the <b>Fort of Saint Elmo</b> which nowadays functions as a War Museum, the <b>Saint Paul's Cathedral</b> and the lower and upper <b>Barakka Gardens</b>, where you can enjoy the views over the harbor of the city, and if you visit around 12 pm, on the upper gardens you can watch a traditional <b>cannon shooting ceremony</b>.
                    <br/><br/>
                    <CaptionImage image="https://live.staticflickr.com/65535/50498521216_40a808d1e8_o.jpg" name="Valletta" caption="The streets of Valletta." />
                    <br/>
                    On the afternoon, visit the 3 cities on the other side of the sea. You can see them from the Barakka Gardens, and they are <b>Birgu</b>, <b>Senglea</b> and <b>Cospicua</b>.
                    To reach them, you can take a ferry from the Upper Barraka Lift to Cospicua for 2.80€ roundtrip, and the journey takes about 10 mins.
                    <br/><br/>
                    Once you reach the other side, you can easily walk through all the three cities and see all they have to offer on foot.
                    Each one offers a unique look at Maltese history, and for being the first home of the <i>Knights Of St. John</i>, these cities have forts, palaces and churches much older than those on Valletta.
                    <br/><br/>
                    <CaptionImage image="https://live.staticflickr.com/65535/50498671022_c92c35eccf_o.jpg" name="Fort St. Angelo" caption="Fort St. Angelo on Birgu." />
                </div>
            },
            {
                "title": "Mdina - Rabat - Dingli Cliffs - Popeye's Village",
                "description": <div>
                    <b>Mdina</b> was the former capital of Malta, a fortified city on top of hill from where you can oversee much of the main island.
                    It is small in size, and a pedestrian only city with its narrow streets and silent charm. The main focus here should be to walk around the city and get a feel for the ancient times of Malta.
                    You can also visit the <b>St. Paul's Cathedral</b>, the <b>Falson Palace</b> and the <b>Knights Of Malta Museum</b>.
                    <br/><br/>
                    Right next to Mdina is the ancient city of <b>Rabat</b>, you can easily reach here from Mdina on foot (it's right outside Mdina's Gate).
                    The city is famous for being the place where <b>Paul the Apostle</b> sought refuge after his ship sank in Malta, and much of the city is dedicated to him.
                    The main sites of interest here are the <b>Parish Church of St. Paul</b> and the <b>St. Paul's Catacombs</b>.
                    <br/><br/>
                    <CaptionImage image="https://live.staticflickr.com/65535/50498670977_9d221bf059_o.jpg" name="Mdina" caption="Walled City of Mdina" />
                    <br/>
                    After lunch, head to the <b>Dingli Cliffs</b>, they are the highest point in Malta, and provide a great walk with amazing scenery.
                    Next head north for two of the best beaches in Malta, <b>Għajn Tuffieħa Bay</b> and <b>Golden Bay</b>. Choose one and spend the rest of the afternoon enjoying the beach and the sea.
                    <br/><br/>
                    When time is almost sunset, go to <b>Popeye's Village</b>, just a few minutes away from the beach.
                    This village was all constructed to serve as a set for the movie "Popeye", and nowadays works as a tourist attraction (and sometimes also hosts weddings).
                    Although, visits inside can be done until 5:30 pm, we believe the best part of it is the view over the village with all its colorful houses.
                    It is a very picturesque scenery, and there is no better time for photos of the village than during sunset.
                    <br/><br/>
                    <CaptionImage image="https://live.staticflickr.com/65535/50497807323_d0203e9f70_o.jpg" name="Popeye's Village" caption="Popeye's Village." />
                </div>
            },
            {
                "title": "Comino and Gozo",
                "description": <div>
                    This day is dedicated to the other islands of Malta. If you are staying here more than 4 days, then staying one full day in <b>Gozo</b> is a great option.
                    <br/><br/>
                    To visit the two islands you have two choices. You can either choose one of the many <b>cruise companies</b> operating full day tours to Comino and Gozo, or if you have a car, bring it with you on a ferry boat.
                    The second option will give you way more freedom and you will be able to enjoy your time better.
                    <br/><br/>
                    If you have a car, catch a ferry to Gozo in the morning in <b>Mellieha</b>, the northern most city of the main island. Once you arrive in Gozo, park your car there and take a small shuttle boat to <b>Comino</b> for about 10€.
                    In Comino you will find perhaps the most famous attraction of Malta, the <b>Blue Lagoon</b>. It is almost like a paradise, white fine sand and crystal clear blue waters, on the downside, it gets crowded rather quickly.
                    As such, it is important that you visit it during the morning before the crowds of tourists from the many cruises arrive here.
                    <br/><br/>
                    On the afternoon, catch the same boat back to Gozo, it is time to explore this island. Gozo is a very small and rural island, and you will find it quite different from Malta.
                    The biggest city here is <b>Victoria</b>, and you can visit the <b>Citadella</b> here, located on top of a hill.
                    Other important stops on the island are the <b>Rotunda St. John Baptist Church</b>, the <b>Ta’Pinu Basilica</b>, the <b>Dwerja Bay</b>, the <b>Wied il-Għasri Bay</b> and the UNESCO World Heritage Site of the <b>Ġgantija Temples</b>.
                    <br/><br/>
                    <CaptionImage image="https://live.staticflickr.com/65535/50498521126_854f9fc552_o.jpg" name="Gozo" caption="Ġgantija Temples in Gozo" />
                </div>
            },
            {
                "title": "Marsaxlokk - Blue Grotto - Saint Peter's Pool",
                "description": <div>
                    Your last day will be spent on the south of the island. Start the day with a visit to <b>Marsaxlokk</b>. This is a fishermen village, and the fishing port in the village gives a very picturesque image with its many colorful boats.
                    If you are visiting here on a Sunday morning, you will have the opportunity to do some shopping at the <b>local market</b>.
                    <br/><br/>
                    Next pay a visit to <b>Blue Grotto</b>, a group of natural sea caves on the coast of the island. You can either admire the scenery from the viewpoint or go for a boat tour inside the structure. The tours last around 30 mins and cost 14€.
                    <br/><br/>
                    On the afternoon visit the <b>Saint Peter's Pool</b>. It is a natural pool formed by the erosion of the rocks by the sea, and the result is a great spot for divers and swimmers.
                    <br/><br/>
                    <CaptionImage image="https://live.staticflickr.com/65535/50498521101_67c413cc75_o.jpg" name="Marsaxlokk" caption="Colorful boats on Marsaxlokk fishing port." />
                </div>
            }
        ]
    },

    "highlights": [
        {
            "name": "Valletta",
            "description": <p>
                    The capital city of Malta, this is the tinniest capital city in the world.
                    Founded in the XVI century, this city is filled with historic and significant buildings, from which the most important is the St. John's Co-Cathedral.
                    From the city's harbor you can see the three old cities of Malta: Birgu, Senglea and Cospicua.
                </p>,
            "image": "https://live.staticflickr.com/65535/50497807258_a6447db888_o.jpg"
        },
        {
            "name": "Blue Lagoon",
            "description": <p>
                Located on the island of Comino, this is the most visited place in the country. With the blue, crystal clear waters of the Mediterranean sea and white sand, this places feels like a paradise on earth.
                One famous drink to try here is the pineapple cocktails, such as the mojito on a pineapple.
            </p>,
            "image": "https://live.staticflickr.com/65535/50498670837_d8644bf083_o.jpg"
        },
        {
            "name": "Ħal Saflieni Hypogeum",
            "description": <p>
                Commonly referred to as <b>Hypogeum</b>, this is a Neolithic subterranean structure dating back to 3200BC, and is one of the oldest and best preserved pre-historic structures on the island.
                It is though that the Hypogeum was used as a <b>sanctuary</b> and a <b>necropolis</b> in ancient times, and is composed of various halls and burial chambers.
                To protect and preserve the place, only 80 people are permitted to visit the place each day, as such tickets tend to sold out weeks or months before the date.
                The Hypogeum as been inscribed as a <b>UNESCO</b> World Heritage Site since 1980.
            </p>,
            "image": "https://live.staticflickr.com/65535/50498670832_11024ccd8b_o.jpg"
        },
        {
            "name": "Mdina",
            "description": <p>
                The ancient capital of Malta, Mdina is a city located at the top of a hill.
                Protected at all sides by a wall, this city still preserves much of its history on its narrow streets and buildings.
            </p>,
            "image": "https://live.staticflickr.com/65535/50498521016_885dc42bcc_o.jpg"
        },
        {
            "name": "Gozo",
            "description": <p>
                The second largest island on the archipelago, Gozo offers a much more rural and tranquil atmosphere than what you will find in Malta.
                Amongst the most famous spots on the island, there are the <b>Rotunda St. John Baptist Church</b>, the <b>Ta’Pinu Basilica</b>, the <b>Dwerja Bay</b>, the <b>Wied il-Għasri Bay</b> and the UNESCO World Heritage Site of the <b>Ġgantija Temples</b>. <b>Victoria</b> is the biggest city on the island, and also deserves a visit.
            </p>,
            "image": "https://live.staticflickr.com/65535/50498670792_ecea26d522_o.jpg"
        },
        {
            "name": "Saint Peter's Pool",
            "description": <p>
                This is a natural pool filled with the Mediterranean Sea. It is a result of the erosion on the rocks by the sea, resulting today in a great place to go for a dive or a swim in the ocean.
            </p>,
            "image": "https://live.staticflickr.com/65535/50498520991_0e633b1c6a_o.jpg"
        }
    ],

    "food": {
        "description": <p>
            Maltese cuisine has seen many different influences from all over the years.
            First it was the Romans, followed by the Arabs in the Middle Ages, the French during their imperial years and finally the British Empire.
            Besides that, the close proximity of Malta with <b>Italy</b>, <b>Tunisia</b> and <b>Lybia</b> also contributed to their cuisine.
            <br/><br/>
            <b>Rabbit meat</b> is one of the traditional dishes of Malta, and is important you try it while on the country.
            For fish options, <b>lampuka</b> is considered the national dish. Other local products include the <b>Gozo cheese</b> and the <b>Maltese Bread</b>. <b>Ftira</b>, other traditional dish, is the Maltese version of pizza.
            <br/><br/>
            Next we give you some suggestion for places where you can eat in Malta.
        </p>,

        "restaurants": [
            {
                "name": "Ta'Kris",
                "description": "This restaurant in Sliema is a great place to taste some local cuisine, namely the rabbit meat. The food is delicious and this is probably one of the best restaurants in Malta.",
                "price": 3,
                "image": "https://live.staticflickr.com/65535/50498670737_81d22c9499_o.jpg"
            },
            {
                "name": "Nenu The Artisan Baker",
                "description": "Set in Valletta, this restaurant only serves dishes made with local products, and is one of the best places to try Ftira.",
                "price": 2,
                "image": "https://live.staticflickr.com/65535/50498670692_704506d2a4_o.jpg"
            },
            {
                "name": "Fontanella Tea Garden",
                "description": "This restaurant in Mdina is the most famous in the city. Although the prices are a bit expensive, the food is great and the place offers great views to the city.",
                "price": 3,
                "image": "https://live.staticflickr.com/65535/50498670677_ba5802ab4e_o.jpg"
            },
            {
                "name": "Pure Living",
                "description": "A cheap place in Sliema to grab something to eat. It is a cheap place that offers great quality in its food, and they mainly serve sandwiches and salads.",
                "price": 1,
                "image": "https://live.staticflickr.com/65535/50498520906_c2c33b16bc_o.jpg"
            },
            {
                "name": "Pineapple Cocktails",
                "description": "When you are on the island of Comino, you will many vendors selling this cocktail. Make sure to give it a try.",
                "price": 1,
                "image": "https://live.staticflickr.com/65535/50498670647_5680c5cf06_o.jpg"
            }
        ]
    },

    "stay": {
        "description": <p>
                The best nightlife of Malta happens in <b>Saint Julian's</b>, and if you are young you will certainly want to spend your time there.
                It is a place that comes to life at night, filled with discos, bares and entertainment that goes on until the sun rises.
                This is the place <b>you should stay</b> if you want to enjoy this nightlife, since being into walking distance of it all is a big plus.
                <br/><br/>
                If on the other end, you come here with your family, or you just don't fancy that stuff, staying in <b>Valletta</b> or in <b>St. Paul's Bay</b> are perhaps the best choices.
                While AirBnb is a great option in Malta, we give you some recommendations of hostels and hotels to stay.
            </p>,
        "budget": [
            {
                "name": "Inhawi Boutique Hostel",
                "description": "This central hostel located in Saint Julian's is the best options for those wanting to have the best time while on the budget. Located 300m from the seafront and boosting a swimming pool, this place is sure to please you. The rooms look new and clean.",
                "image": "https://live.staticflickr.com/65535/50498520846_84383b27b3_o.jpg",
                "link": "/"
            }
        ],
        "mid": [
            {
                "name": "be.Hotel",
                "description": "Located in the heart of Saint Julian's, this hotel offers a pleasant stay. With large and clean rooms, a great panoramic pool and a bunch of shops and bars nearby, this is a great place if you have some money to spare.",
                "image": "https://live.staticflickr.com/65535/50498670597_bc317422da_o.jpg",
                "link": "/"
            },
            {
                "name": "Grand Harbour Hotel",
                "description": "Located in Valletta, this is a good medium-option choice for your stay.",
                "image": "https://live.staticflickr.com/65535/50498520821_5f1be639ef_o.jpg",
                "link": "/"
            }
        ],
        "high": [
            {
                "name": "The Phonicia Malta",
                "description": "Simply put, the best hotel you can find in Malta. Located in Valletta, this 5 star hotel has everything you can ask for, with an incredible infinity pool on the terrace overlooking the harbor.",
                "image": "https://live.staticflickr.com/65535/50498670542_1f5413c74d_o.jpg",
                "link": "/"
            },
            {
                "name": "AX The Palace",
                "description": "Located in the heart of Sliema, within a walking distance of Saint Julian's, this a luxurious stay with an amazing pool on the rooftop overviewing the city and the Mediterranean Sea.",
                "image": "https://live.staticflickr.com/65535/50498520811_2d76d5a22b_o.jpg",
                "link": "/"
            }
        ]
    }

}
