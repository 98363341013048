import React from 'react';
import { Figure } from 'react-bootstrap';

function CaptionImage(props) {
    return (
        <React.Fragment>
            <Figure.Image className="col-12 col-lg-10 offset-lg-1" width="100%" src={ props.image } alt={ props.name } fluid />
            <Figure.Caption className="text-center">{ props.caption }</Figure.Caption>
        </React.Fragment>
    );
}

export default CaptionImage;
