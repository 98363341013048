import React from 'react';
import SmallCountryGuide from '../../../components/guides/SmallCountryComponent';
import Footer from '../../../components/FooterComponent';

import { Malta } from '../../../guides/countries/malta';

function MaltaPage(props) {
    return (
        <div>
            <SmallCountryGuide country={ Malta } pathname={ props.location.pathname } urlBack="/travel-guides/europe" />
            <Footer />
        </div>
    );
}

export default MaltaPage;
